import {
  BlogCardFragment,
  ButtonFragment,
  CtaBlockFragment,
  EventCardFragment,
  HeadingFragment,
  IconsFragment,
  ImageFragment,
  VideoFragment,
  MessageBoxFragment,
} from '@data/strapi/Common/Fragments';

import { DynamicZones } from '@data/strapi/Common/DynamicZones';
import { SeoFields } from '@data/strapi/Common/Seo';

export const GET_HOMEPAGE = /* GraphQL */ `
  query getHomepage($locale: I18NLocaleCode) {
    homepage(locale: $locale) {
      data {
        attributes {
          surveyBar {
            text {
              value
            }
            button {
              ...ButtonFragment
            }
          }
          intro{
            ...CtaBlockFragment
          }
          video{
            ...VideoFragment
          }
          section2Title{
            ...HeadingFragment
          }
          section2Boxes{
            ...IconsFragment
          }
          section2Image{
            image{
              data{
                attributes{
                  ...ImageFragment
                }
              }
            }
          }
          section3Title{
            ...HeadingFragment
          }
          section3Boxes{
            ...IconsFragment
          }
          section3Image{
            image{
              data{
                attributes{
                  ...ImageFragment
                }
              }
            }
          }
          content {
            ${DynamicZones}
          }
          corporateMessagebox {
            ...MessageBoxFragment
          }
          disclaimer {
            value
          }
          blog_title{
            ...HeadingFragment
          }
          blog_article{
            data{
              attributes{
                ...BlogCardFragment
              }
            }
          }
          events_title{
            ...HeadingFragment
          }
          events{
            data{
              attributes{
                ...EventCardFragment
              }
            }
          }
          ${SeoFields}
        }
      }
    }
  }

  ${HeadingFragment}
  ${ImageFragment}
  ${ButtonFragment}
  ${IconsFragment}
  ${VideoFragment}
  ${CtaBlockFragment}
  ${BlogCardFragment}
  ${EventCardFragment}
  ${MessageBoxFragment}
`;
