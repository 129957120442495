const LocaleDateFormatOptions: Intl.DateTimeFormatOptions = {
	year: "numeric",
	month: "long",
	day: "numeric",
};

export const formatDateInterval = (dateParams) => {
	const { startDate, endDate, startTime, endTime } = dateParams;
	const datesComparison = compareDateDays(startDate, endDate);

	if (datesComparison.isSameDay) {
		return formatFinalDate({ startDate, startTime, endTime });
	} else {
		return formatFinalDate(dateParams);
	}
};

const compareDateDays = (startDate, endDate): { isSameDay: boolean; diffInDays: number } => {
	if (startDate === endDate || !endDate)
		return {
			isSameDay: true,
			diffInDays: 0,
		};

	const startDateInMils = new Date(startDate).getTime();
	const endDateInMils = new Date(endDate).getTime();

	const diff = Math.round((endDateInMils - startDateInMils) / 60 / 60 / 1000);
	return {
		isSameDay: false,
		diffInDays: diff,
	};
};


const formatFinalDate = (dateParams) => {
	const { startDate, endDate, startTime, endTime } = dateParams;
	const hoursFormatedString = !endDate
		? `${(startTime as string)}` : `${(startTime as string)} to ${(endTime as string)}`;

	const DateFormatedString = !endDate
		? new Date(startDate).toLocaleDateString('en', LocaleDateFormatOptions)
		: `${new Date(startDate).toLocaleDateString('en', LocaleDateFormatOptions)} 
            to 
            ${new Date(endDate).toLocaleDateString('en', LocaleDateFormatOptions)}`;

	if (hoursFormatedString === '00:00 to 00:00') return `${DateFormatedString}`;

	return `${DateFormatedString} - ${hoursFormatedString} `;
};


export const convertTo24HourFormat = (time) => {
	const timeRegex = /^(1[0-2]|0?[1-9]):([0-5][0-9]) (a.m|p.m)$/;
	if (!timeRegex.test(time)) {
		console.log("Invalid 12-hour time format.");
	}

	const [timePart, designator] = time.split(" ");
	const [hour, minute] = timePart.split(":");
	let convertedHour = +hour;

	if (designator === "pm" && convertedHour !== 12) {
		convertedHour += 12;
	} else if (designator === "am" && convertedHour === 12) {
		convertedHour = 0;
	}

	return `${convertedHour.toString().padStart(2, "0")}:${minute}`;
}