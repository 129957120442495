import { ImageWrapper } from '@data/strapi/Common/Types'
import React from 'react'
import styles from "./ImageWrapper.module.scss";
import cn from 'classnames'

const ImageWrapper = ({
  aspectRatioHeight,
  caption,
  href,
  linkTarget,
  rel,
  className,
  style,
  children
}: ImageWrapper) => {
  return (
    <picture className={cn(styles.imageWrapper, className)} style={style}>
      <div
        aria-hidden="true"
        style={
          aspectRatioHeight ? { paddingBottom: `${aspectRatioHeight}%` } : {}
        }
      ></div>
      {children}
      {!!caption && (
        <figcaption>
          <span>{caption}</span>
        </figcaption>
      )}
      {!!href && (
        <a className={'linker'} href={href} target={linkTarget} rel={rel}></a>
      )}
    </picture>
  )
}

export default ImageWrapper
