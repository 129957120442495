import { PageData } from "@data/strapi/Common/Types";
import _ from "lodash";

export const transformHomepage = (data: any): PageData => {
  if (data && data?.homepage?.data?.attributes) {
    const attributes = data?.homepage?.data?.attributes;

    const page: any = {};

    /* page content */
    page.content = attributes;

    /* SEO */
    const { seo } = attributes;
    page.seo = seo;

    return page;
  } else {
    return {
      page: null,
      error: "No data found for this page",
      loading: false
    };
  }
};
