export const HeadingFragment = `
  fragment HeadingFragment on ComponentDesignSystemHeading {
    __typename
    value
    semantic
    size
    weight
  }
`;

export const ImageFragment = `
  fragment ImageFragment on UploadFile {
    __typename
    url
    alternativeText
    caption
    width
    height
  }
`;

export const ButtonFragment = `
  fragment ButtonFragment on ComponentDesignSystemButton {
    __typename
    label
    url
    variation
  }
`;

export const MediaTextFragment = `
  fragment MediaFragment on ComponentDesignSystemMediaText {
    __typename
    mediaPosition
    withBackground
    media{
      image {
        data {
          attributes {
            ...ImageFragment
          }
        }
      }
    }
    overline {
      value
      icon
    }
    heading {
      ...HeadingFragment
    }
    description {
      value
    }
    buttons {
      ...ButtonFragment
    }
  }
`;

export const IconsFragment = `
  fragment IconsFragment on ComponentDesignSystemIconBox {
    __typename
    icon
    iconColor
    iconPosition
    heading {
      ...HeadingFragment
    }
    description {
      value
    }
    url
  }
`;


export const ImageCardFragment = `
  fragment ImageCardFragment on ComponentDesignSystemImageCard {
    __typename
    image {
      image {
        data {
          attributes {
            ...ImageFragment
          }
        }
      }
    }
    title
    text {
      value
    }
  }
`;

export const VideoFragment = `
  fragment VideoFragment on ComponentDesignSystemVideo {
    __typename
    heading {
      ...HeadingFragment
    }
    description {
      value
    }
    video_file {
      data {
        attributes {
          ...ImageFragment
        }
      }
    }
    video_embed 
  }
`;

export const MessageBoxFragment = `
  fragment MessageBoxFragment on ComponentDesignSystemMessageBox {
    __typename
    boxVariation
    overline {
      value
      icon
    }
    heading {
      ...HeadingFragment
    }
    description {
      value
    }
    buttons {
      ...ButtonFragment
    }
  }
`;

export const CtaBlockFragment = `
  fragment CtaBlockFragment on ComponentCommonCtaBlock {
    __typename
    overline {
      value
      icon
    }
    heading {
      ...HeadingFragment
    }
    description {
      value
    }
    buttons {
      ...ButtonFragment
    }
  }
`;

// Post types

export const BlogCardFragment = `
  fragment BlogCardFragment on BlogArticle {
    publishedAt
    title
    slug
    excerpt 
    blog_categories {
      data {
        attributes {
          label
          slug
        }
      }
    }
  	blog_series {
      data {
        attributes {
          label
          slug
        }
      }
    }
    featuredImage{
      image{
        data{
          attributes{
            ...ImageFragment
          }
        }
      }
    }
    authors{
      data{
        attributes{
          name
          slug
          avatar{
            data{
              attributes{
                ...ImageFragment
              }
            }
          }
        }
      }
    }
  }
`;

export const EventCardFragment = `
  fragment EventCardFragment on Event {
    title
    slug
    start
    end
    startDate
    startTime
    endDate
    endTime
    excerpt
    locationType
    location
    timezone
    hostedBy
    hostedByUsaid
    featuredImage{
      image{
        data{
          attributes{
            ...ImageFragment
          }
        }
      }
    }
  }
`;

export const SimpleCardFragment = `
  fragment SimpleCardFragment on ComponentCommonSimpleCard {
    label
    image{
      data{
        attributes{
          ...ImageFragment
        }
      }
    }
  }
`;

export const ImpactCardFragment = `
  fragment ImpactCardFragment on ComponentCommonImpactCard {
    title
    excerpt
    industry {
      data {
        attributes {
          label
          slug
        }
      }
    }
    businessType
    image{
      data{
        attributes{
          ...ImageFragment
        }
      }
    }
    url
  }
`;
