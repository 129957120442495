export const DynamicZones = `
  ... on ComponentDesignSystemHeading {
    __typename
    ...HeadingFragment
  }
  ... on ComponentDesignSystemText {
    __typename
    value
  }
  ... on ComponentDesignSystemButton {
    __typename
    ...ButtonFragment
  }
  ... on ComponentDesignSystemImage {
    __typename
    image{
      data{
        attributes{
          ...ImageFragment
        }
      }
    }
  }
  ... on ComponentDesignSystemSpacer {
    __typename
    space
  }
  ... on ComponentDesignSystemMediaText {
    __typename
    mediaPosition
    withBackground
    media {
      image {
        data {
          attributes {
            ...ImageFragment
          }
        }
      }
    }
    overline {
      value
      icon
    }
    heading {
      ...HeadingFragment
    }
    description {
      value
    }
    buttons {
      ...ButtonFragment
    }
  }
  ... on ComponentDesignSystemIconBox {
    __typename
    iconColor
    iconPosition
    heading {
      ...HeadingFragment
    }
    description {
      value
    }
    icon
  }
  ... on ComponentDesignSystemMessageBox {
    __typename
    boxVariation
    overline {
      value
      icon
    }
    heading {
      ...HeadingFragment
    }
    description {
      value
    }
    buttons {
      ...ButtonFragment
    }
  }
  ... on ComponentDesignSystemVideo {
    __typename
    heading {
      ...HeadingFragment
    }
    description {
      value
    }
    video_file {
      data {
        attributes {
          ...ImageFragment
        }
      }
    }
    video_embed 
  }
`;
