export const SeoFields = `
    seo {
        metaTitle
        metaDescription
        metaImage {
            data {
                attributes {
                    ...ImageFragment
                }
            }
        }
        keywords
    }
`;
