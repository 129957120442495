import React from 'react';
import { VideoEmbedProps } from '@data/strapi/Common/Types';
import styles from './VideoEmbed.module.scss';

function getVideoUrl(url: string | undefined) {
  if (!url) return '';
  const youTubeRegExp =
    /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const vimeoRegExp =
    /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/i;
  const matchYt = url.match(youTubeRegExp);
  const matchVimeo = url.match(vimeoRegExp);
  if (matchYt && matchYt[2].length === 11)
    return `https://www.youtube.com/embed/${matchYt[2]}`;
  if (matchVimeo && matchVimeo[1].length > 0)
    return `https://player.vimeo.com/video/${matchVimeo[1]}`;
  return '';
}

const VideoEmbed = ({ link }: VideoEmbedProps) => {
  const videoUrl = getVideoUrl(link);
  if (videoUrl === '') return <p>Invalid URL</p>;
  return (
    <div>
      <div className={styles.videoResponsive}>
        <iframe
          src={videoUrl}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded Video"
        />
      </div>
    </div>
  );
};

export default VideoEmbed;
