import React, { CSSProperties } from 'react';

import cn from 'classnames';
import styles from './Chip.module.scss';

interface IChip {
  className?: string;
  style?: CSSProperties;
  children?: React.ReactNode;
}

const Chip = ({ children, className, style, ...props }: IChip) => {
  return (
    <div className={cn(styles.chip, 'h6', className)} style={style} {...props}>
      {children}
    </div>
  );
};

export default Chip;