import BlogCard from '@components/molecules/BlogCard/BlogCard';
import Button from '@components/atoms/Button/Button';
import DisplayImage from '@components/atoms/DisplayImage/DisplayImage';
import EventCard from '@components/molecules/EventCard/EventCard';
import { GET_HOMEPAGE } from '@data/strapi/Homepage/Query/GetHomepage/Homepage.query';
import Head from 'next/head';
import Heading from '@components/atoms/Heading/Heading';
import IconBox from '@components/molecules/IconBox/IconBox';
import Overline from '@components/atoms/Overline/Overline';
import { PageData } from '@data/strapi/Common/Types';
import { ReactSVG } from 'react-svg';
import RichText from '@components/atoms/RichText/RichText';
import Spacer from '@components/atoms/Spacer/Spacer';
import SurveyBar from '@components/molecules/SurveyBar/SurveyBar';
import Video from '@components/atoms/Video/Video';
import classNames from 'classnames';
import { createSeo } from '@constants/utils';
import displayBlock from '@utils/displayBlock';
import { fetcherStrapi } from '@lib/fetcherStrapi';
import styles from '@styles/pages/Homepage.module.scss';
import useHomepage from '@data/strapi/Homepage/Query/GetHomepage/Homepage';
import MessageBox from '@components/molecules/MessageBox/MessageBox';

export default function Home(params: {
  initialData: PageData;
  variables: any;
}) {
  const { page, error } = useHomepage(params.initialData, params.variables);

  const intro = page?.content?.intro || {};
  const video = page?.content?.video || {};
  const section2Title = page?.content?.section2Title || {};
  const section2Boxes = page?.content?.section2Boxes || {};
  const section2Image = page?.content?.section2Image || {};
  const section3Title = page?.content?.section3Title || {};
  const section3Boxes = page?.content?.section3Boxes || {};
  const section3Image = page?.content?.section3Image || {};
  const dynamic = page?.content?.content || {};
  const corporateMessagebox = page?.content?.corporateMessagebox || {};
  const disclaimer = page?.content?.disclaimer || {};
  const blog_title = page?.content?.blog_title || {};
  const blog_article = page?.content?.blog_article || {};
  const events_title = page?.content?.events_title || {};
  const events = page?.content?.events || {};
  const surveyBar = page?.content?.surveyBar || {};

  const articlesGrid = blog_article?.data?.attributes;
  const eventsGrid = events?.data;

  return (
    <>
      <Head>{createSeo(page?.seo)}</Head>
      {surveyBar && Object.keys(surveyBar).length > 0 && (
        <SurveyBar
          label={surveyBar?.text?.value}
          button={surveyBar?.button}
        ></SurveyBar>
      )}
      <div className={styles.intro}>
        <div className="container">
          <div className="row align-items-center pt-6 pb-6">
            <div className="column col-12 col-md-1"></div>
            <div className="column col-12 col-md-4">
              {intro?.overline && (
                <Overline {...intro?.overline} className="color-white mb-2" />
              )}
              {intro?.heading && (
                <Heading {...intro?.heading} className="color-white" />
              )}
              {intro?.description && (
                <RichText
                  value={intro?.description.value}
                  className="color-white mt-3"
                />
              )}
              {intro?.buttons &&
                intro?.buttons.length > 0 &&
                intro?.buttons.map((button, index) => {
                  return (
                    <Button
                      key={`button-${index}`}
                      {...button}
                      className="mt-4"
                    />
                  );
                })}
            </div>
            <div className="column col-12 col-md-1"></div>
            <div className="column col-12 col-md-5">
              {video && (
                <>
                  <ReactSVG
                    src="assets/homepage/video-top.svg"
                    className="d-flex justify-content-end"
                    title='video-top'
                  />
                  <Video {...video} />
                  <ReactSVG src="assets/homepage/video-bottom.svg" title='video-bottom'/>
                </>
              )}
            </div>
            <div className="column col-12 col-md-1"></div>
          </div>
        </div>
      </div>
      <Spacer space={6} />
      {section2Boxes && section2Boxes.length > 0 && (
        <div className="container">
          <div className="row">
            <div className="column col-12 col-md-1"></div>
            <div className="column col-12 col-md-10">
              {section2Title && <Heading {...section2Title} className="mb-4" />}
              <div className="container-fluid">
                <div className="row">
                  <div className="column col-12 col-md-8">
                    <div
                      className="d-flex white-box h-md-100 p-0 mb-3"
                      style={{ border: '1px solid #f4f7fc' }}
                    >
                      {section2Image &&
                        section2Image?.image?.data?.attributes && (
                          <div
                            className="d-none d-md-block w-100"
                            style={{ maxWidth: '220px' }}
                          >
                            <DisplayImage
                              src={section2Image?.image?.data?.attributes.url}
                              width={
                                section2Image?.image?.data?.attributes.width
                              }
                              height={
                                section2Image?.image?.data?.attributes.height
                              }
                              alt={
                                section2Image?.image?.data?.attributes
                                  .alternativeText
                              }
                              style={{
                                objectFit: 'cover',
                                height: 'calc(100% - 8px)',
                              }}
                            />
                          </div>
                        )}
                      <IconBox
                        {...section2Boxes[0]}
                        className={'align-self-center p-4'}
                      />
                    </div>
                  </div>
                  <div
                    className={classNames(
                      'column col-12 col-md-4',
                      styles.section2Cards
                    )}
                  >
                    {section2Boxes.slice(1).map((icon, index) => {
                      return (
                        <IconBox key={`icon-${index}`} {...icon} boxed={true} />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="column col-12 col-md-1"></div>
          </div>
        </div>
      )}

      {dynamic && dynamic.length > 0 && (
        <div className="container">
          <div className="row">
            <div className="column col-12">
              <Spacer space={6} />

              {dynamic.map((block, index) => {
                return displayBlock(block, index);
              })}
            </div>
          </div>
        </div>
      )}

      <Spacer space={6} />

      <div className="container">
        <div className="row">
          <div className="column col-12 col-md-1"></div>
          <div className="column col-12 col-md-10">
            {section3Title && <Heading {...section3Title} className="mb-4" />}

            <div className="container-fluid">
              <div className="row">
                <div className="column col-12 col-md-6">
                  {section3Image && section3Image?.image?.data?.attributes && (
                    <DisplayImage
                      src={section3Image?.image?.data?.attributes.url}
                      width={section3Image?.image?.data?.attributes.width}
                      height={section3Image?.image?.data?.attributes.height}
                      alt={
                        section3Image?.image?.data?.attributes.alternativeText
                      }
                    />
                  )}
                </div>
                <div className="column col-12 col-md-6">
                  {section3Boxes &&
                    section3Boxes.length > 0 &&
                    section3Boxes.map((icon, index) => {
                      return (
                        <IconBox
                          key={`icon2-${index}`}
                          {...icon}
                          boxed={true}
                          className="mt-3"
                        />
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
          <div className="column col-12 col-md-1"></div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="column col-12 corporate-variation">
            <Spacer space={6} />
            {corporateMessagebox && <MessageBox {...corporateMessagebox} disclaimer={disclaimer}/>}
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="column col-12 col-md-6 mt-6">
            {blog_title && <Heading {...blog_title} className="mb-3" />}
            {articlesGrid && articlesGrid?.title && (
              <BlogCard {...articlesGrid} variation="lg" />
            )}
          </div>
          <div className="column col-12 col-md-6 mt-6">
            {events_title && <Heading {...events_title} className="mb-3" />}
            {eventsGrid && eventsGrid.length > 0 && (
              <>
                {eventsGrid.map((event, i) => {
                  return (
                    <EventCard
                      key={`event-${i}`}
                      {...event.attributes}
                      variation="sm"
                    />
                  );
                })}
              </>
            )}
          </div>
        </div>
      </div>
      <Spacer space={6} />
    </>
  );
}

export async function getStaticProps({ locale }) {
  const variables = { locale };
  const data = await fetcherStrapi(GET_HOMEPAGE, variables);

  if (Object.keys(data).length <= 0) {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      initialData: data,
      variables,
    },
    revalidate: 120,
  };
}
