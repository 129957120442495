import DisplayImage from '../DisplayImage/DisplayImage';
import Heading from '../Heading/Heading';
import React from 'react';
import styles from './Authors.module.scss';

interface IAuthors {
  postAuthors?: any;
  className?: string;
}

const Authors = ({ postAuthors, className, ...props }: IAuthors) => {
  let authorsImg: string[] = [];
  let authorsLabel = '';
  let authorsCount = 0;

  if (postAuthors) {
    if (postAuthors?.name) {
      authorsImg.push(postAuthors.avatar?.data?.attributes.url || '/assets/common/avatar-placeholder.png');
      authorsLabel = postAuthors.name;
    } else if (postAuthors.length > 0) {
      if (postAuthors.length > 1) {
        postAuthors.map((author, i) => {
          if (i > 1) authorsCount++;
          else authorsImg.push(author?.attributes?.avatar?.data?.attributes.url || '/assets/common/avatar-placeholder.png');

          authorsLabel = `${authorsLabel}, ${author?.attributes?.name}`;
        });

        authorsLabel = authorsLabel.slice(2);
      } else {
        authorsImg.push(postAuthors[0].attributes.avatar?.data?.attributes.url || '/assets/common/avatar-placeholder.png');
        authorsLabel = postAuthors[0].attributes?.name;
      }
    }
  } else {
    return <></>;
  }

  return (
    <div className="d-flex align-items-center mt-2" style={{gap: "16px"}}>
      <div className={styles.authorImg}>
        {authorsImg && authorsImg.map(img => {
          return (
            <DisplayImage
              width={24}
              height={24}
              src={img}
              alt={authorsLabel}
              style={{
                objectFit:
                  !img ||
                    img !== '/assets/common/avatar-placeholder.png'
                    ? 'cover'
                    : 'contain',
              }}
            />
          )
        })}

        {authorsCount > 0 && (
          <div className={styles.authorCounter}>
            <span className='d-block h6'>{authorsCount}</span>
          </div>
        )}
      </div>
      <span className="h6">{authorsLabel}</span>
    </div>
  );
};

export default Authors;
