import "video-react/styles/scss/video-react.scss";

import React, { useEffect, useRef } from "react";

import Heading from "../Heading/Heading";
import { Player } from "video-react";
import { ReactSVG } from "react-svg";
import RichText from "../RichText/RichText";
import VideoEmbed from "../VideoEmbed";
import { VideoProps } from "@data/strapi/Common/Types";
import styles from "./Video.module.scss";

const Video = ({
  heading,
  description,
  video_file,
  video_embed,
  ...props
}: VideoProps) => {
  // TODO : fix typescript type
  const videoRef = useRef<any>(null);

  const videoUrl = video_file?.data?.attributes.url;

  useEffect(() => {
    if (!videoRef.current) return;

    const options = {
      rootMargin: "0px",
      threshold: [0.25, 0.75],
    };

    const handlePlay = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          !videoRef.current?.querySelector(".video-react-poster") &&
            videoRef.current?.querySelector("video").play();
        } else {
          !videoRef.current?.querySelector(".video-react-poster") &&
            videoRef.current?.querySelector("video").pause();
        }
      });
    };

    const observer = new IntersectionObserver(handlePlay, options);
    observer.observe(videoRef.current);
    const videoElem = videoRef.current;

    return () => {
      observer.unobserve(videoElem);
    };
  }, [videoRef]);


  useEffect(() => {
    if (!videoRef.current) return;

    const video = videoRef.current.querySelector('video');
    if(video){
      video.load();
      video.play();
    }
  }, [videoUrl]);

  return (
    <div className={styles.video}>
      {heading && <Heading {...heading} className="" />}
      {description && (
        <RichText
          value={
            typeof description === "string"
              ? description
              : (description as any).value
          }
          className="mt-2 mb-5"
        />
      )}
      {videoUrl ? (
        <div ref={videoRef}>
          <Player muted={true}>
            <source src={videoUrl} />
          </Player>
        </div>
      ):  <VideoEmbed link={video_embed}/>}
    </div>
  );
};

export default Video;
