import React, { MouseEventHandler, useState } from 'react';

import Button from "@components/atoms/Button/Button";
import Heading from "@components/atoms/Heading/Heading";
import { MessageBoxProps } from "@data/strapi/Common/Types";
import Overline from "@components/atoms/Overline/Overline";
import RichText from "@components/atoms/RichText/RichText";
import cn from "classnames";
import styles from "./MessageBox.module.scss";
import { Modal } from '../Modal';

export default function MessageBox({
  boxVariation,
  overline,
  heading,
  description,
  buttons,
  fullWidth,
  className,
  disclaimer,
}: MessageBoxProps) {
  const [open, setOpen] = useState<boolean>(false);

  const content = (
    <>
      <div
        className={cn(
          styles.messageBox,
          styles[`variation_${boxVariation}`],
          "p-6 ps-4 pe-4",
          className
        )}
      >
        {overline && <Overline {...overline} className="mb-2" />}
        {heading && <Heading {...heading} />}

        {description && (
          <RichText
            value={
              typeof description === "string"
                ? description
                : (description as any).value
            }
            className="mt-3"
          />
        )}
        {buttons && buttons.length>0 &&
          buttons.map((button, index) => {
            return (
              <Button key={`button-${index}`} {...button} className="mt-4" />
            );
          })}
        {disclaimer && (
            <i
                className={`${styles.disclaimer} icon-info mt-4`}
                onClick={() => {
                  setOpen(true);
                }}
            />
        )}
      </div>

    {open && disclaimer && disclaimer.value && (
      <Modal
          labelOk="Done"
          handleCancel={() => {
            setOpen(false);
          }}
          handleOk={() => {
            setOpen(false);
          }}
          className={`${styles.modal} color-gray-70`}
      >
        <RichText
            value={disclaimer.value}
            className="color-gray-70 text-start mt-4"
        />
      </Modal>
      )}
    </>
  );

  if (fullWidth) {
    return content;

  } else {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="column col-md-1"></div>
          <div className="column col-md-10">{content}</div>
          <div className="column col-md-1"></div>
        </div>
      </div>
    );
  }
}
