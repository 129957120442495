import { GET_HOMEPAGE } from "./Homepage.query";
import { fetcherStrapi } from "@lib/fetcherStrapi";
import { transformHomepage } from "./Homepage.transform";
import { useMemo } from "react";
import useSWR from "swr";

export default function useHomepage(initialData, variables) {
  const { data, error, isValidating, mutate } = useSWR(
    [GET_HOMEPAGE, useMemo(() => variables, [variables])],
    fetcherStrapi,
    {
      initialData,
      revalidateOnFocus: false,
    }
  );
  let page: any = undefined;
  if (data && !error) page = transformHomepage(data);

  return {
    loading: isValidating,
    page,
    mutate,
    error,
  };
}
