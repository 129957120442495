import dynamic from 'next/dynamic';

const Button = dynamic(() => import('@components/atoms/Button'));
const ContentImage = dynamic(() => import('@components/atoms/ContentImage'));
const Spacer = dynamic(() => import('@components/atoms/Spacer'));
const Heading = dynamic(() => import('@components/atoms/Heading'));
const IconBox = dynamic(() => import('@components/molecules/IconBox'));
const MessageBox = dynamic(() => import('@components/molecules/MessageBox'));
const Video = dynamic(() => import('@components/atoms/Video'));
const RichText = dynamic(() => import('@components/atoms/RichText'));
const MediaText = dynamic(() => import('@components/organisms/MediaText'));

// Decide which block component to display.
export default function displayBlock(block, index) {
  const { __typename } = block;
  // prettier-ignore
  try {
    switch (__typename) {
      case 'ComponentDesignSystemHeading':
        return <Heading {...block} key={index} />
      case 'ComponentDesignSystemText':
        return <RichText {...block} key={index} />
      case 'ComponentDesignSystemImage':
        return <ContentImage 
          key={index} 
          src={block?.image?.data?.attributes?.url}
          alt={block?.image?.data?.attributes?.alternativeText}
          caption={block?.image?.data?.attributes?.caption}
          width={block?.image?.data?.attributes?.width}
          height={block?.image?.data?.attributes?.height}
        />
      case 'ComponentDesignSystemButton':
        return <Button {...block} key={index} />
      case 'ComponentDesignSystemVideo':
        return <Video {...block} key={index} />
      case 'ComponentDesignSystemSpacer':
        return <Spacer {...block} key={index} />
      case 'ComponentDesignSystemMediaText':
        return <MediaText {...block} key={index} />
      case 'ComponentDesignSystemIconBox':
        return <IconBox {...block} key={index} />      
      case 'ComponentDesignSystemMessageBox':
        return <MessageBox {...block} key={index} /> 
      default:
        if (process.env.NODE_ENV === 'development') {
          return <pre key={index}>{JSON.stringify(block, null, 2)}</pre>
        } else {
          return <></>
        }
    }
  } catch (error) {
    console.log("some error");
    return <h1></h1>
  }
}
