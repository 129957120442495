import Authors from '@components/atoms/Authors/Authors';
import Chip from '@components/atoms/Chip/Chip';
import DisplayImage from '@components/atoms/DisplayImage/DisplayImage';
import Heading from '@components/atoms/Heading';
import { IBlogCard } from './BlogCard.interface';
import ImageWrapper from '@components/atoms/ImageWrapper';
import Link from 'next/link';
import React from 'react';
import RichText from '@components/atoms/RichText/RichText';
import classNames from 'classnames';
import styles from './BlogCard.module.scss';
import { useRouter } from 'next/router';

const BlogCard = ({
  id,
  variation = 'md',
  blog_series,
  publishedAt,
  title,
  slug,
  excerpt,
  featuredImage,
  authors,
  className,
}: IBlogCard) => {
  const dateString = new Intl.DateTimeFormat('en', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }).format(new Date(publishedAt));
  const router = useRouter();

  const lang = router.locale;

  const postSeries = (blog_series as any | undefined)?.data;
  const imageUrl = featuredImage?.image?.data?.attributes?.url;
  const imageAlt = featuredImage?.image?.data?.attributes?.alternativeText;
  const postAuthors = authors?.data;

  return (
    <figure
      className={classNames(
        styles.card,
        styles[`variation_${variation}`],
        className
      )}
    >
      <ImageWrapper aspectRatioHeight={55}>
        <DisplayImage
          fill={true}
          src={imageUrl && imageUrl}
          alt={imageAlt || title}
        />
      </ImageWrapper>
      <figcaption>
        {postSeries && postSeries.length > 0 && (
          <div className={styles.categories}>
            {postSeries.map((serie, index) => {
              const { label, slug } = serie.attributes;

              return (
                <Chip
                  key={`serie-${index}`}
                  className={'d-table fw-semi_bold mb-2'}
                >
                  <Link href={`/blog?series=${slug}`}>{label}</Link>
                </Chip>
              );
            })}
          </div>
        )}
        {dateString && (
          <Heading
            value={dateString}
            semantic={'span'}
            size={'h6'}
            weight={'normal'}
            className={classNames(styles.postDate)}
          />
        )}
        {title && (
          <Heading
            value={title}
            semantic={'h3'}
            size={variation === 'lg' ? 'h3' : 'h4'}
            className={classNames(styles.postTitle, 'text-ellipsis')}
          />
        )}

        {excerpt && variation !== 'sm' && (
          <div className="mobile-hidden">
            <RichText
              tag="p"
              value={excerpt}
              className={'text-ellipsis mt-2'}
            ></RichText>
          </div>
        )}

        <Authors postAuthors={postAuthors} />
      </figcaption>
      <a className={'linker'} aria-label={slug} href={`/${lang}/blog/${slug}`}></a>
    </figure>
  );
};

export default BlogCard;
