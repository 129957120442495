import React from "react";
import cn from "classnames";
import styles from "./Spacer.module.scss";
import { SpacerProps } from "@data/strapi/Common/Types";

const Spacer = ({ space, ...props }: SpacerProps) => {
  const elClass = cn(
    styles.spacer,
    `mt-${space}`
  );

  return <div className={elClass}></div>;
};

export default Spacer;
