import Chip from '@components/atoms/Chip/Chip';
import DisplayImage from '@components/atoms/DisplayImage/DisplayImage';
import Heading from '@components/atoms/Heading';
import { IEventCard } from './EventCard.interface';
import ImageWrapper from '@components/atoms/ImageWrapper';
import React from 'react';
import RichText from '@components/atoms/RichText/RichText';
import classNames from 'classnames';
import { formatDateInterval } from './EventCard.helpers';
import styles from './EventCard.module.scss';
import { useRouter } from 'next/router';

function formatDateHour(dateObject) 
  {
    if (dateObject && dateObject.indexOf("Z") !== -1)
    {
      const isoString = `1970-01-01T${dateObject}`; // Date part is needed for valid Date object
      const date = new Date(isoString);
      
      const timeString = date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
      const result = timeString.replace('AM', 'a.m.').replace('PM', 'p.m.');;
      return result;
    }
    return dateObject;
  }

const EventCard = ({
  variation = 'md',
  start,
  end,
	startDate,
	startTime,
	endDate,
	endTime,
  title,
  slug,
  excerpt,
  location,
  locationType,
  hostedByUsaid,
  featuredImage,
}: IEventCard) => {
  const router = useRouter();

  const lang = router.locale;
  const dateSObject = start ? start.split('T') : '';
  const dateEObject = end ? end.split('T') : dateSObject;

  const dateParams = {
    startDate: startDate || dateSObject[0],
    endDate: endDate || dateEObject[0],
    startTime: formatDateHour(startTime || dateSObject[1]),
    endTime: formatDateHour(endTime || dateEObject[1]),
  };


  const imageUrl = featuredImage?.image?.data?.attributes?.url;
  const imageAlt = featuredImage?.image?.data?.attributes?.alternativeText;

  const locationColor =
    locationType === 'Hybrid'
      ? '#339BED'
      : locationType === 'Online'
      ? '#D62C40'
      : '#F3A614';
  const locationStr =
    locationType === 'Hybrid'
      ? 'Hybrid'
      : locationType === 'Online'
      ? 'Virtual'
      : 'In person';

  return (
    <figure
      className={classNames(
        styles.card,
        styles[`variation_${variation}`],
      )}
    >
      <ImageWrapper aspectRatioHeight={55}>
        <DisplayImage
          fill={true}
          src={imageUrl && imageUrl}
          alt={imageAlt || title}
        />
      </ImageWrapper>
      <figcaption>
        {hostedByUsaid && (
          <div className={styles.categories}>
            <Chip className={'d-table fw-semi_bold mb-2'}>Hosted by USAID</Chip>
          </div>
        )}
        {startDate && (
          <Heading
            value={formatDateInterval(dateParams)}
            semantic={'span'}
            size={'h6'}
            weight={'normal'}
            className={classNames('postDate')}
          />
        )}
        {title && (
          <Heading
            value={title}
            semantic={'h3'}
            size={variation === 'lg' ? 'h3' : 'h4'}
            className={'text-ellipsis'}
          />
        )}

        {excerpt && variation !== 'sm' && (
          <div className="mobile-hidden">
            <RichText
              tag="p"
              value={excerpt}
              className="text-ellipsis mt-2"
            ></RichText>
          </div>
        )}

        <div className="d-flex">
          {locationType && (
            <div className={'d-flex align-items-center mt-3 me-4'} style={{gap: "8px"}}>
              <span
                className={styles.location}
                style={{ backgroundColor: locationColor }}
              ></span>
              <Heading
                value={locationStr}
                semantic={'span'}
                size={'h6'}
                weight={'normal'}
                className="color-gray-70"
              />
            </div>
          )}

          {location && (
            <div className={'d-flex align-items-center mt-3'} style={{gap: "8px"}}>
              <i className="icon-location color-secondary-450"></i>
              <Heading
                value={location}
                semantic={'span'}
                size={'h6'}
                weight={'normal'}
                className="color-gray-70"
              />
            </div>
          )}
        </div>
      </figcaption>
      <a className={'linker'} aria-label={slug} href={`/${lang}/events/${slug}`}></a>
    </figure>
  );
};

export default EventCard;
